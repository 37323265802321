

#renderTable thead {
  width: 100%;
}

#renderTable tr th {
  font-size: 12px;
  font-weight: 500;
  color: #66758a;
}

#renderTable tr th:nth-child(1) {
  width: 25%;
}

#renderTable tr th:nth-child(2) {
  width: 15%;
}

#renderTable tr th:nth-child(3) {
  width: 13%;
}

#renderTable tr th:nth-child(4) {
  width: 13%;
}

#renderTable tr th:nth-child(5) {
  width: 15%;
}

#renderTable tr th:nth-child(6) {
  width: 15%;
}

#renderTable tr th:nth-child(7) {
  width: 15%;
}

.loading {
  width: 100%;
  height: 100px;
  position: relative;
}

.loading .spinner {
  position: absolute;
  left: 45%;
  top: 100%;
}

.symbolList{
  max-height: 200px;
  overflow-y: scroll;
}