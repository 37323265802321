:root{
  --color-primary-hovered: #295FA6;
}

.app {
  overflow: auto;
  background-color: #f3f1f0;
}

html {
  scroll-behavior: smooth;
  overflow: auto;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body {
  background-color: #f3f1f0;
  overflow: auto;
 
}

.custom-toast {
  font-size: 20px;
  width: 34vw;
  padding: 30px 20px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

input:focus{
  border-color: lightgray !important;
}

.primaryGradient {
  background-image: linear-gradient(178deg, #213753 100%, #285EA4 99.5%);
}

.name {
  color: #92fb61;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Toastify__toast--success {
  color: #fff !important;
  border-radius: 10px !important;
  background: #242c32 !important;
}

.Toastify__toast--error {
  color: #fff !important;
  border-radius: 10px !important;
  background: #242c32 !important;
}

.Toastify__toast--info {
  color: #fff !important;
  border-radius: 10px !important;
  background: #242c32 !important;
}

.Toastify__close-button {
  color: #fff !important;
}

.swiper-nav-btns{
  background-color: #3f4852;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-primary-hovered); /* Color of the thumb */
  border-radius: 5px; /* Radius of the thumb */
}

::-webkit-scrollbar-track {
  background-color: #f3f1f0; /* Color of the track */
}

/* For Firefox */
body {
  scrollbar-width: thin;
  scrollbar-color: var(--color-primary-hovered) #f3f1f0;
}

/* For Microsoft Edge (Chromium-based) */
body {
  scrollbar-width: thin;
  scrollbar-color: var(--color-primary-hovered) #f3f1f0;
}