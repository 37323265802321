.table-container {
  width: 64%;
}

.page-title {
  width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.page-title .input-group {
  width: 40%;
}

.table-row {
  display: flex;
  flex-direction: row;
  gap: 30px;
  background-color: white;
  padding: 16px;
  border-radius: 16px;
}

.bulk-deals {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 50px;
}

.bulk-deals .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
}

.bulk-deals .title .title-right{
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.bulk-deals .title img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.bulk-deals .title .max {
  width: 30px;
  height: 30px;
  cursor: pointer;
  transform: rotate(90deg);
}

.bulk-deals .title h1 {
  
  font: 700 24px/24px 'Lato', sans-serif;
  text-transform: uppercase;
}

.bulk-deals .table-content {
  width: 100%;
  /* max-height: 200px; */
  overflow-y: scroll;
  width: 100%;
}

.bulk-deals .table-content table {
  max-height: 300px;
  overflow-y: scroll;
  width: 100%;
}

.bulk-deals .table-content table thead {
  position: sticky;
  top: 0;
  border: 1px solid #DDDDDD;
}

.bulk-deals .table-content table tr {
  margin-bottom: 20px;
  width: 100%;
}

.bulk-deals .table-content table th {
  text-align: left;
  padding-left: 10px;
  color: white;
  height: 40px;
 
}

.bulk-deals .table-content table tr th:nth-child(1) {
  padding-left: 5px;
}

.bulk-deals .table-content table tr th:nth-child(3) {
  width: 20%;
}

.bulk-deals .table-content table tr th:nth-child(4) {
  width: 30%;
}

.bulk-deals .table-content table tr td {
  height: 45px;
  padding: 10px 5px;
  font: 700 14px 'Lato', sans-serif;
  color: rgb(120, 132, 128);
  font-weight: 500;
  text-align: left;
  padding-left: 10px;
  border-bottom: 0px dashed #c8c8c8;
}

.bulk-deals .table-content table tr td:nth-child(1) {
  padding-left: 5px;
  text-align: left;
}

.bulk-deals .table-content table tr td:nth-child(5) {
  padding-left: 5px;
  text-align: center;
}

.bulk-deals .table-content table tr td:nth-child(6) {
  padding-left: 5px;
  text-align: center;
}

.bulk-deals .table-content table tr td:nth-child(7) {
  padding-left: 5px;
  text-align: center;
}

.table-row-2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  width: 80%;
  height: auto;
}

.bulk-csv {
  width: 100%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 50px;
}

.bulk-csv .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 10px;
}

.bulk-csv .title img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.bulk-csv h1 {
  
  font: 900 20px/24px 'Lato', sans-serif;
  text-transform: uppercase;
}

.table-content {
  /* max-height: 200px; */
  overflow-y: scroll;
  width: 100%;
}

.table-content::-webkit-scrollbar {
  display: none;
}

.bulk-csv thead {
  position: sticky;
  top: 0;
  border: 1px solid #DDDDDD;
}

.bulk-csv table {
  max-height: 300px;
  overflow-y: scroll;
  width: 100%;
}

.bulk-csv table::-webkit-scrollbar {
  display: none;
}

.bulk-csv table th {
  text-align: left;
  padding-left: 10px;
  color: white;
  height: 40px;
  
}

.bulk-csv table th:nth-child(3) {
  text-align: center;
}

.bulk-csv table th:nth-child(4) {
  text-align: center;
}

.bulk-csv table th:nth-child(5) {
  text-align: center;
}

.bulk-csv table th:nth-child(6) {
  text-align: center;
}

.bulk-csv table tr {
  margin-bottom: 20px;
  width: 100%;
}

.bulk-csv table tr th:nth-child(1) {
  padding-left: 5px;
}

.bulk-csv table tr td {
  height: 45px;
  padding: 10px 5px;
  font: 700 14px 'Lato', sans-serif;
  color: rgb(120, 132, 128);
  font-weight: 500;
  text-align: left;
  padding-left: 10px;
}

.bulk-csv table tr td:nth-child(1) {
  padding-left: 5px;
  text-align: left;
  width: 10%;
}

.bulk-csv table tr td:nth-child(3) {
  text-align: left;
}

.bulk-csv table tr td:nth-child(4) {
  text-align: center;
}

.bulk-csv table tr td:nth-child(5) {
  text-align: center;
}

.bulk-csv table tr td:nth-child(6) {
  text-align: center;
}

.bulk-csv table tr td:nth-child(7) {
  text-align: left;
  width: 15%;
}

.spinner {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.spinner img {
  height: 40px;
  width: 40px;
}