.watchlist-table {
  width: 100%;
}

.watchlist-table td {
  height: 30px;
}

.watchlist-table td:nth-child(1) {
  width: 35%;
}

.watchlist-table td:nth-child(2) {
  width: 15%;
}

.watchlist-table th:nth-child(3) {
  width: 50%;
  padding-left: 40px;
}

.watchlist-table td:nth-child(3) {
  width: 50%;
  padding-left: 40px;
}