.news-card {
  /* width: 350px; */
  /* height: 110px; */
  
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin-bottom: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.news-card .news-body {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
}

.news-card a .news-body img {
  width: 48px;
  height: 35px;
  margin-top: 5px;
}

.news-card-body {
  width: 310px;
  height: 70px;
  padding: 10px;
  padding-top: 2px;
}

.published {
  max-height: 100px;
  margin-bottom: 2px;
}

.published span {
  color: rgb(108, 117, 125);
  font-size: 12px;
  height: 20px;
}

.published-date {
  display: flex;
  width: 140px;
  height: 20px;
}

.title span {
  font-size: 13px;
  color: black;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.summary span {
  font-size: 14px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
}

.know-more button a {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
  color: blue;
}

.know-more button img {
  width: 20px;
  height: 20px;
}

.tag-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  height: 20px;
  /* overflow: hidden; */
}

.tag-container-disabled{
  display: none;
  height: 20px;
}

.subject span {
  font-size: 12px;
  color: rgb(108, 117, 125);
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-height: 18px;
}
