@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Apply the font globally */
body,
span,
div,
table,
th {
  font-family: 'Open Sans', sans-serif !important;
}
