#table-body tr {
  height: 47px;
}

#table-body tr td {
  font-size: 14px;
}

#table-body tr td:nth-child(1) {
  /* font-weight: 700; */
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  height: 47px;
}

#symbol img {
  width: 20px;
  height: 20px;
  border-radius: 100%;
}

#table-body tr td:nth-child(7) {
  padding-left: 20px;
}

#table-body tr td:nth-child(7) span {
  cursor: pointer;
}