.stock-card-container{
    /* margin-left: 20%; */
    width: 83%
}



.stock-button{
    height: 50px;
   display: flex;
   justify-content: center;
  
   margin: 16px;
   border-bottom: 1px solid #dddddd;
   padding-bottom: 10px;
}

.stock-button button{
    width: 80px;
    border: 1px solid rgb(168, 167, 167);
   
}

.selected{
    background-color: rgb(238, 247, 255);
    
}

.stock-card{
    width: 230px;  
}

.stock-card img{
    height: 30px;   
}