.news-container {
    min-width: 83%;
    margin-left: 200px;
    
}

.news-card-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 6px;
  justify-content: space-between;
}

.latest-news {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 16px;
  border-bottom: 0.5px solid #dddddd
}

.top-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 15vw;
  align-items: center;
}

.newsTags {
 
  
  
  display: flex;
  margin-left: 0px;
  overflow-x: scroll;
  overflow-y: hidden;
}

.newsTags::-webkit-scrollbar {
  height: 0px;
  width: 5px;

}

.newsTags::-webkit-scrollbar-thumb {
  /* background-color: #6C4E62; */
  border-radius: 100px;
}

.newsTags span {
  /* border: 1px solid #8D6A81; */
  margin: 3px;
  height: 30px;
  width: fit-content;
  white-space: nowrap;
  overflow-wrap: normal;
  margin-right: 7px;
  border-radius: 6px;
  padding: 3px;
  font-size: 14px;
  color: #3F4852;
  cursor: pointer;
}

.date {
  font-size: 12px;
  color: darkslategray;
}

.latest-news h5 {
  font-size: 16px;
  cursor: pointer;
}

.latest-news h5:hover {
  color: blue;
}

.latest-news .search {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.news-spinner {
  height: 250px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.news-spinner img {
  height: 40px;
  width: 40px;
}

.page1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.more-news {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: right;
  width: 77%;
  
  font-size: 12px;
  color: blue;
  position: relative;
}

.pagination {
  margin-top: 16px;
  
}

.more-news span {
  cursor: pointer;
 
}

.next {
  position: absolute;
  right: 0px;
 
}

.next img {
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-top: 0px;
  margin-bottom: 16px;
}

.previous {
  position: absolute;
  left: 0px;
  margin-top: 0px;
  margin-bottom: 16px;
}

.previous img {
  transform: rotate(180deg);
  width: 30px;
  height: 30px;
  cursor: pointer;
}

@media (max-width:639px){
  .news-container {
    margin-left: 0vw;
  }
}