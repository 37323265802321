.tag, .tag-full-length {
  display: inline-block;
  text-align: center;
  line-height: 14px;
  padding: 0px 5px 3px 15px;
  position: relative;
  max-height: 15px;
  overflow: hidden;
  margin-top: 5px;
  font-size: 12px;
  color: #fff;
}

.tag {
  max-width: 80px;
}

.tag-full-length {
  max-width: 100%;
}

.tag:before, .tag-full-length:before{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-left: 12px solid #fff;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  width: 0px;
}
